<template>
  <div class="UserCountrySelect country-select" v-if="$locale">
    <div class="UserCountrySelectContent">
      <div class="search-block view-card">
        <input type="search" :placeholder="$locale['object']['user-profile']['search-country-label']" @keyup="searchCountry" />
      </div>
      <CountryDeafult v-if="countries && countries.length === allCountries.length" :countries="countries" @country="setCountry" />
      <div class="view-card">
        <ul class="country-list">
          <li class="country-list-item" v-for="(country, index) in countries" :key="`c-${index}`" @click="setCountry(country)">
            <span class="country-list-item-flag" :style="`--flag:url(${country.flag})`"></span>
            <span class="country-list-item-name">{{ country.name }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["onSelect", "modal"],
  components: {
    CountryDeafult: () => import("./CountryDeafult.vue"),
  },
  data: function() {
    return {
      countries: null,
      allCountries: null,
    };
  },
  methods: {
    setCountry: function(country) {
      this.modal.close(() => {
        if (Go.is(this.onSelect, "function")) {
          this.onSelect(country);
        }
      });
    },
    searchCountry: function($event) {
      const word = $event.target.value;
      if (!word) {
        this.countries = this.allCountries;
      } else {
        this.countries = this.allCountries.filter((item) => {
          return item.name.toLowerCase().startsWith(word.toLowerCase());
        });
      }
    },
    getCountries: async function() {
      const countries = require(`../../assets/util/restcountries.all.json`);
      this.countries = countries;
      this.allCountries = countries;
    },
  },
  mounted: function() {
    this.getCountries();
  },
};
</script>

<style lang="scss">
.country-select {
  .view-card {
    padding: 0;
    overflow: hidden;
    &.--default {
      margin: 0 0 $mpadding 0;
    }
  }
  .search-block {
    position: sticky;
    top: $header_height;
    margin: 0 0 $mpadding 0;
    @include Flex(inherit, center, center);
    input[type="search"] {
      padding: $mpadding $mpadding $mpadding $mpadding * 3.3;
      font-size: 100%;
      width: 100%;
      border: none;
      background: none;
      appearance: none;
    }
    &:before {
      font-family: fontawesome;
      content: "\f002";
      position: absolute;
      left: $mpadding + 3;
      pointer-events: none;
      color: $sec_color;
    }
  }
  .country-list {
    &-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: $mpadding/2 $mpadding;
      &:hover {
        background-color: #e9e9e9;
        font-weight: bold;
      }
      &-flag {
        @include Flex(inherit, center, center);
        width: $mpadding * 3;
        height: $mpadding * 3;
        min-width: $mpadding * 3;
        background-image: var(--flag);
        background-position: center;
        background-size: cover;
        border-radius: 50%;
      }
      &-name {
        margin: 0 0 0 $mpadding;
      }
    }
  }
}
</style>
